@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');


.learn-more {
  margin-bottom: 0px; 
  padding: 10px;
  background-color: rgba(247, 224, 191, 0.748);
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.learn-container h2 {
/* text-align: center; */
font-weight: 700;
}

.learn-container {
  /* background-image: url(/public/images/savannahbg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-color: rgb(131, 171, 123);
  font-size:clamp(15px, 4vw, 18px);
  font-family: 'Montserrat', sans-serif;
  color:black;
  /* font-weight: 300; */
}

.content-section {
  /* background-color: rgba(251, 244, 235, 0.643); */
  margin: 25px;
  margin-top:0;
  margin-bottom: 0;
  padding: 10px;
  padding-bottom: 0px;
}

.learn-row {
  background-color: rgba(250, 169, 88, 0.738);
  padding: 5px;
  padding-top: 10px;
}

.what-row {
  background-color: rgb(169, 183, 167);
  padding: 5px;
  padding-top: 10px;
}

.why-row {
  background-color: rgba(251, 244, 235, 0.696);
  padding-top: 5px;
}

.why-col {
  margin: 5px;
  padding: 10px;
}

.how-col {
  margin: 5px;
  padding: 10px;
}

