/* .trash-button {
  color: black;
  background-color: transparent;
  border-color: transparent;
} */

#trash-button .btn {
  background-color: #f3ebd299;
  color: rgb(0, 0, 0);
  font-size: 25px;
  width: 100px;
  border-color: rgb(246, 136, 2) ;
}