@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');


.signup-container {
  height: 100vh;
  background-image: url(/public/images/anemone.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Montserrat', sans-serif;
}

#signup-col {
  /* width: 190px; */
  /* height: 254px; */
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  margin-top: 190px;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
              0 0  0 2px rgb(190, 190, 190),
              0.3em 0.3em 1em rgba(0,0,0,0.3);
}

#signup-col h2 {
  text-align: center;
}


#signup-col .btn {
  --color: #00A97F;
  padding: 0.8em 1.7em;
  background-color: transparent;
  border-radius: .3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
  margin-top: 10px;
 }
 
 .signup-button::before, .button::after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
 }
 
 .signup-button::before {
  top: -1em;
  left: -1em;
 }
 
 .signup-button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
 }
 
 .signup-button:hover::before, .button:hover::after {
  height: 410px;
  width: 410px;
 }
 
 .signup-button:hover {
  color: rgb(10, 25, 30) !important;
 }
 
 .signup-button:active {
  filter: brightness(.8);
 }
 

