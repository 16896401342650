.favbutton-col .liked-button {
  background-color: #f3ebd299;
  color: red;
  width: 170px;
  font-size: 25px;
  border-color: rgb(246, 136, 2);
}

.favbutton-col .unliked-button {
  background-color: #f3ebd299;
  color: red;
  font-size: 25px;
  width: 170px;
  border-color: rgb(246, 136, 2) ;
  
}

