@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');

.logo-image {
  height: 90px;
  width: 264px;
  border-radius: 10px;
}

.navbar-container {
  /* background-color: #F2F1EE; */
  background-color: rgb(131, 171, 123);
  font-size: 25px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.navbar-container .nav-link {
  --bs-nav-link-color: rgb(42, 41, 41);
}

.navbar-collapse {
  justify-content: space-between;
}


.sticky-top {
  padding: 0 !important; 
}
