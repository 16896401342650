@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');


/* .modal-content {
  backdrop-filter: blur(2px);
  backdrop-filter: brightness(60%);
  backdrop-filter: contrast(40%);
  backdrop-filter: drop-shadow(4px 4px 10px blue);
  backdrop-filter: grayscale(30%);
  backdrop-filter: hue-rotate(120deg);
  backdrop-filter: invert(70%);
  backdrop-filter: opacity(20%);
  backdrop-filter: sepia(90%);
  backdrop-filter: saturate(80%);
} */


#organization {
  /* background-color: #f9e8d041; */
  background-image: url(/public/images/rainforestBG.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Montserrat', sans-serif;
  /* height: 100%; */
}


#organization img {
  aspect-ratio: 1 / 1;
  object-fit: contain;

}

.modal {
  font-family: 'Montserrat', sans-serif;
  border-radius: 0;
  backdrop-filter: blur(2px);
}


.modal img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.modal-content img {
  height: 325px;
  
}

.modal-content a{
  color:black
}

.org-card button {
  --bs-btn-hover-color: orange;
  --bs-btn-hover-bg: #d5f3d299 ;
  --bs-btn-hover-border-color: rgb(246, 136, 2);
}

.org-card .moreinfo-button {
  background-color: #f3ebd299;
  border-color: rgb(246, 136, 2);
  color: rgb(60, 57, 57);
  font-size: 20px;
  padding: 10px;
}


/* controlling delete and fav button */
.org-col .col {
  padding: 2px;
}


/* .org-card{
  background-color: rgb(27, 208, 99) !important;
} */

.org-card {
  /* width: 300px; */
  /* height: 254px; */
  
  border-radius: 13px !important;
  padding: 5px;
  box-shadow: rgba(71, 252, 65, 0.2) 0 15px 30px -5px;
  background-image: linear-gradient(144deg,#3e8741, #f3b542 50%,#8deb00c5);
}


/*
.card__content {
  background: rgb(5, 6, 45);
  border-radius: 17px;
  width: 100%;
  height: 100%;
} */