@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* .modal-content {
  border: 0px !important; 
} */

/* #root {
  background-color: #bfe17ba6;
  background-image: url('/public/images/CLbg-removebg-preview.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */
html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* border: 5px solid red; */
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.video-content {
  background-color: rgba(250, 247, 251, 0.356);
  /* margin-top: 20%; */
  margin-top: 40px;
  /* margin-right: 0px; */
  margin-left: 50px;
  /* width: 300px; */
  padding: 15px;
  border-radius: 6px;
  font-family: 'Bebas Neue', sans-serif;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
             0 0  0 3px rgb(190, 190, 190),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
} 

.video-content h1 {
  font-size: 50px;
  color: rgba(255, 64, 0, 0.811);
}
