@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');



.getinvolved-container {
  /* background-image: url(/public/images/penguins.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-color: rgb(131, 171, 123);
  font-size:clamp(15px, 4vw, 18px);
  color: black;
  font-family: 'Montserrat', sans-serif;
}

.GI {
  margin-bottom: 0px;
  padding: 10px;
  background-color: rgba(247, 224, 191, 0.868);
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.getinvolved-container h3 {
  font-weight: 600;
  padding-bottom: 20px;
}

.GI-section {
  margin: 25px;
  margin-top:0;
  margin-bottom: 0;
  padding: 10px;
  padding-bottom: 0px;
}

.GI-row {
  margin: 10px;
  margin-bottom: 0;
  padding: 10px;
  padding-top: 15px;
  background-color: rgb(169, 183, 167);
  /* background-color: rgba(250, 176, 102, 0.738); */
}

.help-row {
  background-color: rgba(251, 244, 235, 0.769);
  margin: 10px;
  margin-bottom: 0px;
  padding: 10px;
  padding-top: 15px;
  
}

.ways-col {
  margin: 5px;
  padding: 10px;
}

.ways2-col {
  margin: 5px;
  padding: 10px;
}

.home-col{
  margin: 5px;
  padding: 10px;  
}

.home2-col {
  margin: 5px;
  padding: 10px;
}

.line-row {
  background-color: rgba(250, 176, 102, 0.738);
  height: 30px;
}